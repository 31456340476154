import * as React from "react"
import type { SVGProps } from "react"
const SvgMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10 14.166a.833.833 0 1 0 0 1.667h.008a.833.833 0 1 0 0-1.667z" />
    <path
      fillRule="evenodd"
      d="M5.833 1.041a2.292 2.292 0 0 0-2.291 2.292v13.333a2.292 2.292 0 0 0 2.291 2.292h8.334a2.292 2.292 0 0 0 2.291-2.292V3.333a2.292 2.292 0 0 0-2.291-2.292zM4.792 3.333c0-.576.466-1.042 1.041-1.042h8.334c.575 0 1.041.466 1.041 1.042v13.333c0 .575-.466 1.042-1.041 1.042H5.833a1.042 1.042 0 0 1-1.041-1.042z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMobile

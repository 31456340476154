import * as React from "react"
import type { SVGProps } from "react"
const SvgIos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M2.5 3.667a2.625 2.625 0 0 1 2.625-2.625h5.167a2.625 2.625 0 0 1 2.625 2.625v.666a.625.625 0 0 1-1.25 0v-.666c0-.76-.616-1.375-1.375-1.375h-.744a.624.624 0 0 1-.59.833h-2.5a.625.625 0 0 1-.59-.833h-.743c-.76 0-1.375.615-1.375 1.375V13c0 .76.616 1.375 1.375 1.375h.583a.625.625 0 1 1 0 1.25h-.583A2.625 2.625 0 0 1 2.5 13z" />
    <path d="m18.006 9.454.032-.022c-.876-1.256-2.208-1.29-2.578-1.29-.567 0-1.074.201-1.5.37-.31.123-.577.228-.792.228-.239 0-.512-.11-.817-.233-.384-.155-.82-.33-1.299-.33-1.617 0-3.26 1.34-3.26 3.867 0 1.576.607 3.236 1.358 4.305.65.91 1.213 1.651 2.022 1.651.383 0 .665-.12.96-.245.328-.139.674-.285 1.197-.285.528 0 .844.137 1.149.27.283.124.557.243.983.243.884 0 1.465-.8 2.022-1.6.624-.91.885-1.804.893-1.845-.05-.017-1.745-.7-1.745-2.63 0-1.553 1.155-2.31 1.375-2.454M15.686 5.261c0 .666-.286 1.332-.69 1.811-.43.532-1.172.927-1.762.927-.068 0-.135-.009-.177-.016a1.194 1.194 0 0 1-.024-.236c0-.675.345-1.34.716-1.761.472-.548 1.255-.961 1.912-.986.017.075.025.169.025.261" />
  </svg>
)
export default SvgIos

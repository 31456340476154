"use client"

import { useAuth, useSession, useUser } from "@clerk/nextjs"
import {
  AnalyticsTracker,
  BackendApi,
  backendApiCall,
  BackendEndpointDef,
  JwtBackendTemplate,
} from "@store-platform/backend/common"
import { hasRole } from "@store-platform/utils"
import { useCallback, useEffect } from "react"
import { create } from "zustand"
import {
  trackClientEvent,
  trackClientIdentify,
  trackPageView,
} from "./analytics"
import {
  getAnonIdCookie,
  getFbBrowserIdCookie,
  getFbClickIdCookie,
  setFbClickIdCookie,
} from "./cookies"

type AnalyticsTrackersStore = {
  ready: boolean
  anonymousId?: string | undefined
  fbBrowserId?: string | undefined
  fbClickId?: string | undefined
  setAnonymousId: (id: string | undefined) => void
  setFbBrowserId: (id: string | undefined) => void
  setFbClickId: (id: string | undefined) => void
  applyFbClickId: (id: string) => void
  setReady: (ready: boolean) => void
}

const useAnalyticsTrackers = create<AnalyticsTrackersStore>((set) => ({
  ready: false,
  anonymousId: undefined,
  fbBrowserId: undefined,
  fbClickId: undefined,
  setAnonymousId: (id: string | undefined) => set({ anonymousId: id }),
  setFbBrowserId: (id: string | undefined) => set({ fbBrowserId: id }),
  setFbClickId: (id: string | undefined) => set({ fbClickId: id }),
  applyFbClickId: (clickId: string) => {
    const fbc = setFbClickIdCookie(clickId)
    set({ fbClickId: fbc })
  },
  setReady: (ready: boolean) => set({ ready }),
}))

export const useAnalytics = () => {
  const { session, isLoaded: isSessionLoaded } = useSession()
  const {
    ready,
    anonymousId,
    fbBrowserId,
    fbClickId,
    setReady,
    setAnonymousId,
    setFbBrowserId,
    setFbClickId,
    applyFbClickId,
  } = useAnalyticsTrackers()

  useEffect(() => {
    if (!anonymousId) setAnonymousId(getAnonIdCookie())
  }, [anonymousId])

  useEffect(() => {
    if (!fbBrowserId) setFbBrowserId(getFbBrowserIdCookie())
  }, [fbBrowserId])

  useEffect(() => {
    if (!fbClickId) setFbClickId(getFbClickIdCookie())
  }, [fbClickId])

  useEffect(() => {
    setReady(isSessionLoaded && anonymousId !== undefined)
  }, [isSessionLoaded, anonymousId])

  const trackEvent = useCallback(
    (tracker: AnalyticsTracker) => {
      trackClientEvent(
        {
          id: session?.user.externalId,
          anonymousId,
          fbBrowserId,
          fbClickId,
        },
        tracker,
      ).then()
    },
    [session?.user.externalId, anonymousId, fbBrowserId, fbClickId],
  )

  const trackPage = useCallback(
    (pathname: string, search?: URLSearchParams) => {
      trackPageView(
        {
          id: session?.user.externalId,
          anonymousId,
          fbBrowserId,
          fbClickId,
        },
        pathname,
        search,
      ).then()
    },
    [session?.user.externalId, anonymousId, fbBrowserId, fbClickId],
  )

  const trackIdentify = useCallback(() => {
    trackClientIdentify({
      id: session?.user.externalId,
      anonymousId,
      fbBrowserId,
      fbClickId,
    }).then()
  }, [session?.user.externalId, anonymousId, fbBrowserId, fbClickId])

  return {
    trackerReady: ready,
    trackEvent,
    trackPage,
    trackIdentify,
    rawFbClickId: fbClickId?.split(".")[fbClickId.split(".")?.length - 1],
    trackFbClickId: applyFbClickId,
  }
}

export const useAnonAnalytics = () => {
  const {
    setAnonymousId,
    anonymousId,
    applyFbClickId,
    fbBrowserId,
    setFbBrowserId,
    fbClickId,
    setFbClickId,
  } = useAnalyticsTrackers()

  useEffect(() => {
    if (!anonymousId) setAnonymousId(getAnonIdCookie())
  }, [anonymousId])

  useEffect(() => {
    if (!fbBrowserId) setFbBrowserId(getFbBrowserIdCookie())
  }, [fbBrowserId])

  useEffect(() => {
    if (!fbClickId) setFbClickId(getFbClickIdCookie())
  }, [fbClickId])

  const trackEvent = useCallback(
    (tracker: AnalyticsTracker) => {
      if (!anonymousId) return
      trackClientEvent(
        {
          id: undefined,
          anonymousId,
          fbBrowserId,
          fbClickId,
        },
        tracker,
      ).then()
    },
    [anonymousId, fbBrowserId, fbClickId],
  )

  const trackPage = useCallback(
    (pathname: string, search?: URLSearchParams) => {
      if (!anonymousId) return
      trackPageView(
        {
          id: undefined,
          anonymousId,
          fbBrowserId,
          fbClickId,
        },
        pathname,
        search,
      ).then()
    },
    [anonymousId, fbBrowserId, fbClickId],
  )

  return {
    trackerReady: anonymousId !== undefined,
    anonymousId,
    trackEvent,
    trackPage,
    rawFbClickId: fbClickId?.split(".")[fbClickId.split(".")?.length - 1],
    trackFbClickId: applyFbClickId,
  }
}

export const useBackendApi = (): BackendApi => {
  const { sessionId, getToken } = useAuth()
  const { anonymousId, fbBrowserId, fbClickId } = useAnalyticsTrackers()

  return useCallback(
    async function <TData, TError = undefined>(
      def: BackendEndpointDef<TData, TError>,
    ) {
      return backendApiCall<TData, TError>(
        def,
        sessionId ? await getToken({ template: JwtBackendTemplate }) : null,
        {
          headers: {
            "X-Custom-Referer": window.location.href,
            "X-Anon-Id": anonymousId || "",
            "X-Fb-Browser-Id": fbBrowserId || "",
            "X-Fb-Click-Id": fbClickId || "",
          },
        },
      )
    },
    [sessionId, getToken, anonymousId, fbBrowserId, fbClickId],
  )
}

export const useRole = () => {
  const { user, isSignedIn } = useUser()
  return isSignedIn
    ? {
        isUser: true,
        isDev: hasRole(user.publicMetadata, "developer"),
        isAdmin: hasRole(user.publicMetadata, "admin"),
      }
    : {
        isUser: false,
        isDev: false,
        isAdmin: false,
      }
}

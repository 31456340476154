"use client"

import { AnalyticsTracker, AnalyticsUser } from "@store-platform/backend/common"

const backendApiRootUrl = process.env["NEXT_PUBLIC_BACKEND_API_ROOT_URL"]
const siteId = process.env["NEXT_PUBLIC_SITE_ID"] || ""

function getHeaders(): HeadersInit {
  return {
    "Content-Type": "application/json",
  }
}

/**
 * Not using sendBeacon on purpose because of ad blockers
 */
const event = async (path: string, body: string) => {
  if (!backendApiRootUrl) return

  const url = `${backendApiRootUrl}${path}`
  const fetchSetting = {
    method: "POST",
    headers: getHeaders(),
    body: body,
  }

  fetch(url, {
    ...fetchSetting,
    keepalive: true,
  }).catch(() => {
    fetch(url, fetchSetting).catch()
  })
}

export async function trackClientEvent(
  user: AnalyticsUser,
  tracker: AnalyticsTracker,
) {
  const body = JSON.stringify({
    si: siteId,
    o: tracker.obj,
    a: tracker.action,
    p: tracker.properties,
    an: user.anonymousId,
    us: user.id,
    u: window.location.href,
    fbp: user.fbBrowserId,
    fbc: user.fbClickId,
  })

  event(`/a/t`, body).catch()
}

export async function trackPageView(
  user: AnalyticsUser,
  pathname: string,
  search?: URLSearchParams,
) {
  const body = JSON.stringify({
    p: pathname,
    r: document.referrer,
    s: search?.size ? `?${search?.toString()}` : undefined,
    t: document.title,
    u: window.location.href,
    an: user.anonymousId,
    us: user.id,
    si: siteId,
    fbp: user.fbBrowserId,
    fbc: user.fbClickId,
  })

  event(`/a/p`, body).catch()
}

export async function trackClientIdentify(user: AnalyticsUser) {
  const body = JSON.stringify({
    an: user.anonymousId,
    us: user.id,
  })

  event(`/a/i`, body).catch()
}

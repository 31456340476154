import * as React from "react"
import type { SVGProps } from "react"
const SvgDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M3.667 1.875A2.625 2.625 0 0 0 1.042 4.5v7.667a2.625 2.625 0 0 0 2.625 2.625h5.708v2.083H6.667a.625.625 0 1 0 0 1.25h6.666a.625.625 0 1 0 0-1.25h-2.708v-2.083h5.708a2.625 2.625 0 0 0 2.625-2.625V4.5a2.625 2.625 0 0 0-2.625-2.625zm12.666 11.667c.76 0 1.375-.616 1.375-1.375V4.5c0-.76-.615-1.375-1.375-1.375H3.667c-.76 0-1.375.616-1.375 1.375v7.667c0 .76.615 1.375 1.375 1.375z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgDesktop

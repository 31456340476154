import { Plan, UserRole } from "@store-platform/types"

export function hasRole(
  claims: { role: UserRole } | null,
  assessedRole: UserRole,
) {
  if (!claims) return false

  switch (assessedRole) {
    case "developer":
      return claims.role === "developer" || claims.role === "admin"
    case "admin":
      return claims.role === "admin"
    default:
      return true
  }
}

export function getAppLimit(plan: Plan | undefined) {
  switch (plan) {
    case "free":
      return 1
    case "verified":
      return 3
    case "growth":
      return 10
    default:
      return 1
  }
}

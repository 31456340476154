import * as React from "react"
import type { SVGProps } from "react"
const SvgMacOs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M2.292 3.796c0-.37.3-.671.671-.671h10.37c.371 0 .672.3.672.671v3.148a.625.625 0 0 0 1.25 0V3.796c0-1.06-.86-1.921-1.922-1.921H2.963c-1.061 0-1.921.86-1.921 1.921v6.482c0 1.06.86 1.921 1.921 1.921h4.56v1.343H6.39a.625.625 0 1 0 0 1.25H9.074a.625.625 0 0 0 0-1.25h-.3v-1.343h.393a.625.625 0 1 0 0-1.25H2.963c-.37 0-.671-.3-.671-.671z" />
    <path d="M18.69 11.76a.511.511 0 0 0 .025-.017c-.674-.966-1.698-.992-1.983-.992-.436 0-.826.154-1.154.284-.238.094-.443.176-.61.176-.183 0-.393-.085-.627-.18-.296-.12-.63-.254-1-.254-1.243 0-2.508 1.03-2.508 2.975 0 1.212.467 2.49 1.045 3.311.5.7.934 1.27 1.556 1.27.295 0 .51-.091.738-.188.252-.107.518-.22.92-.22.407 0 .65.106.885.208.218.096.428.187.756.187.68 0 1.127-.615 1.555-1.23.48-.7.681-1.388.687-1.42-.039-.013-1.342-.538-1.342-2.022 0-1.195.888-1.778 1.057-1.889M16.906 8.534c0 .512-.22 1.024-.531 1.393-.33.41-.901.714-1.355.714a.784.784 0 0 1-.136-.013.918.918 0 0 1-.02-.181c0-.52.267-1.032.552-1.356.363-.42.965-.739 1.47-.758a.96.96 0 0 1 .02.201" />
  </svg>
)
export default SvgMacOs

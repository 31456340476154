import * as React from "react"
import type { SVGProps } from "react"
const SvgWebapp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M1.042 5a2.292 2.292 0 0 1 2.291-2.292H15A2.292 2.292 0 0 1 17.292 5v.833a.625.625 0 0 1-1.25 0V5c0-.575-.467-1.042-1.042-1.042H3.333c-.575 0-1.041.467-1.041 1.042v8.333c0 .576.466 1.042 1.041 1.042h3.334a.625.625 0 1 1 0 1.25H3.333a2.292 2.292 0 0 1-2.291-2.292z" />
    <path
      fillRule="evenodd"
      d="M8.542 12.917a5.208 5.208 0 1 1 10.416 0 5.208 5.208 0 0 1-10.416 0m1.299-.625a3.964 3.964 0 0 1 2.485-3.07 7.637 7.637 0 0 0-.997 3.07zm0 1.25h1.488a7.637 7.637 0 0 0 .997 3.07 3.964 3.964 0 0 1-2.485-3.07m7.818 0a3.964 3.964 0 0 1-2.485 3.07 7.637 7.637 0 0 0 .997-3.07zm-3.909 2.97a6.388 6.388 0 0 0 1.163-2.97h-2.326a6.389 6.389 0 0 0 1.163 2.97m0-7.19a6.389 6.389 0 0 0-1.163 2.97h2.326a6.388 6.388 0 0 0-1.163-2.97m1.424-.1c.545.937.887 1.984.997 3.07h1.488a3.964 3.964 0 0 0-2.485-3.07"
      clipRule="evenodd"
    />
    <path d="M3.958 6.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25M6.25 5.625a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0M7.292 6.25a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25" />
  </svg>
)
export default SvgWebapp

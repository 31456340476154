import * as React from "react"
import type { SVGProps } from "react"
const SvgAndroid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M6.848 1.02a.158.158 0 0 1 .215.062l.794 1.432a5.29 5.29 0 0 1 2.15-.45c.769 0 1.498.161 2.148.45l.794-1.432a.158.158 0 1 1 .277.153l-.785 1.416c1.51.786 2.53 2.281 2.53 3.996v.061H5.04v-.061c0-1.715 1.02-3.21 2.53-3.996l-.785-1.416a.158.158 0 0 1 .062-.215m.866 2.975a.415.415 0 1 0 0 .83.415.415 0 0 0 0-.83m4.584 0a.415.415 0 1 0 0 .83.415.415 0 0 0 0-.83"
      clipRule="evenodd"
    />
    <path d="M3.513 6.843c-.615 0-1.113.499-1.113 1.113v4.638a1.113 1.113 0 1 0 2.226 0V7.956c0-.614-.498-1.113-1.113-1.113M16.5 6.843c.614 0 1.112.499 1.112 1.113v4.638a1.113 1.113 0 1 1-2.226 0V7.956c0-.614.498-1.113 1.113-1.113M5.04 7.273v6.958a1.2 1.2 0 0 0 1.201 1.2h.742v2.456a1.113 1.113 0 1 0 2.226 0v-2.455h1.54v2.455a1.113 1.113 0 0 0 2.225 0v-2.455h.797a1.2 1.2 0 0 0 1.2-1.2V7.272z" />
  </svg>
)
export default SvgAndroid

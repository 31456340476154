import * as React from "react"
import type { SVGProps } from "react"
const SvgHeadset = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M15.512 8.457c-.258-.181-.758-.396-1.658-.672-.796-.243-2.278-.405-3.888-.405-1.61 0-3.092.162-3.888.405-.9.276-1.396.49-1.658.672-.257.18-.281.333-.234.476.048.143.305.134.791.067l.297-.04a23.58 23.58 0 0 1 1.876-.193 74.408 74.408 0 0 1 2.806-.072c.329 0 1.968.024 2.807.072.788.046 1.408.13 1.873.193l.3.04c.485.062.742.076.79-.067.048-.143.029-.295-.234-.476z" />
    <path
      fillRule="evenodd"
      d="M8.45 13.928A3.435 3.435 0 0 1 10 13.54c.507 0 1.023.123 1.55.387l.004.001c.292.144.532.323.807.533l.004.003.025.02c.277.208.596.448 1.003.625.432.189.933.296 1.586.296 1.892 0 3.365-1.412 3.859-3.388.242-.973.107-2.178-.294-3.236-.399-1.054-1.108-2.082-2.132-2.593-1.02-.51-2.353-.747-3.528-.863-1.188-.118-2.289-.118-2.88-.118h-.008c-.591 0-1.692 0-2.88.118-.999.099-2.112.285-3.05.652a.625.625 0 0 0-1-.52l-.942.71a.625.625 0 0 0 .18 1.092 5.711 5.711 0 0 0-.848 1.522c-.4 1.058-.536 2.262-.294 3.235.494 1.976 1.967 3.39 3.86 3.39.652 0 1.153-.108 1.585-.297.407-.177.726-.417 1.003-.626l.025-.019.004-.003c.275-.21.515-.389.807-.533zM7.24 6.57c-1.139.112-2.283.333-3.093.738-.642.32-1.187 1.03-1.522 1.917-.335.883-.416 1.823-.25 2.49.391 1.565 1.465 2.441 2.646 2.441.507 0 .834-.081 1.086-.191.264-.115.477-.274.775-.498.272-.207.598-.455 1.01-.658A4.684 4.684 0 0 1 10 12.29c.717 0 1.423.175 2.108.518.413.203.739.451 1.01.658.298.224.511.383.775.498.252.11.58.191 1.086.191 1.181 0 2.255-.876 2.646-2.44.166-.668.085-1.608-.25-2.491-.335-.887-.88-1.598-1.522-1.917-.81-.405-1.954-.626-3.092-.738A27.855 27.855 0 0 0 10 6.458c-.59 0-1.638 0-2.761.112"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHeadset
